import * as styles from './style.css'
import iconPanelDashboard from '~/assets/img/icon-panel-dashboard.svg'
import iconDocumentPen from '~/assets/img/icon-document-pen.svg'
import iconDocumentCheck from '~/assets/img/icon-document-check.svg'
import iconTriangleDown from '~/assets/img/icon-triangle-down.svg'
import iconDelete from '~/assets/img/icon-delete.svg'
import iconUndo from '~/assets/img/icon-undo.svg'
import iconClose from '~/assets/img/icon-close.svg'
import iconDescription from '~/assets/img/icon-description.svg'

type Props = {
  className?: string
  color?: 'light' | 'sub'
  size?: 'small' | 'medium'
  variant:
    | 'panelDashboard'
    | 'documentPen'
    | 'documentCheck'
    | 'triangleDown'
    | 'delete'
    | 'undo'
    | 'close'
    | 'description'
}

export const SvgIcon = ({ className, color = 'light', size = 'medium', variant }: Props) => {
  let maskImage = iconPanelDashboard

  switch (variant) {
    case 'documentPen':
      maskImage = iconDocumentPen
      break
    case 'documentCheck':
      maskImage = iconDocumentCheck
      break
    case 'triangleDown':
      maskImage = iconTriangleDown
      break
    case 'delete':
      maskImage = iconDelete
      break
    case 'undo':
      maskImage = iconUndo
      break
    case 'close':
      maskImage = iconClose
      break
    case 'description':
      maskImage = iconDescription
      break
    default:
      break
  }

  return (
    <i
      className={[
        styles.svgIcon,
        styles[`svgIcon__${color}`],
        styles[`svgIcon__${size}`],
        styles[`svgIcon__${variant}`],
        className,
      ]
        .filter(Boolean)
        .join(' ')}
      style={{
        WebkitMaskImage: `url(${maskImage})`,
        maskImage: `url(${maskImage})`,
      }}
    />
  )
}
